<template>
  <div>
    <hr class="m-1" />

    <b-row>
      <b-col class="mb-1" cols="12">
        <b-form-checkbox @change="hideShowFilter"
          ><b><u>show search filters</u></b></b-form-checkbox
        >
      </b-col>
    </b-row>

    <b-card border-variant="primary" no-body v-if="this.filtersHidden == false">
      <div>
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="12">
              <b-form @submit.prevent>
                <b-row>
                  <!-- <b-col md="6">
                    <b-form-group label="Title">
                      <v-select
                        v-model="filterTempMovie"
                        placeholder="Title"
                        label="full_title"
                        :options="optionTempMovie"
                      />
                    </b-form-group>
                  </b-col> -->
                  <b-col md="6">
                    <b-form-group label="Licensee/Licensor">
                      <v-select
                        v-model="selectedCustomer"
                        placeholder="Licensee/Licensor"
                        label="company"
                        :options="optionCustomer"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- 
                  <b-col md="3">
                    <b-form-group label="Rights">
                      <v-select
                        :closeOnSelect="false"
                        :reduce="(label) => label.lbl_id"
                        v-model="subRights"
                        multiple
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="sub_rights_list"
                        label="lbl_name"
                        value="lbl_id"
                        placeholder="Select Sub Rights"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Revenue Type">
                      <v-select
                        v-model="selectedRight"
                        placeholder="Revenue Type"
                        label="title"
                        :options="optionRights"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Revenue Status">
                      <v-select
                        v-model="selectedStatus"
                        placeholder="Revenue Status"
                        label="title"
                        :options="optionStatus"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Revenue Date Range">
                      <flat-pickr
                        :disabled="
                          selectedStatus && selectedStatus.value == '3'
                            ? true
                            : false
                        "
                        v-model="revenueDateRange"
                        placeholder="Revenue Date"
                        class="form-control"
                        :config="{ mode: 'range', ...$flatPickrConfig }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Royalty Report Revenue">
                      <v-select
                        v-model="selectedRelevance"
                        placeholder="Royalty Report Revenue"
                        label="title"
                        :reduce="(r) => r.value"
                        :options="relevanceOptions"
                      />
                    </b-form-group>
                  </b-col> -->

                  <b-col class="mt-2" md="6">
                    <b-button
                      :disabled="
                        selectedStatus && selectedStatus.value == '3'
                          ? true
                          : false
                      "
                      @click="onSearch()"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-info"
                      class="mr-1"
                    >
                      Search
                    </b-button>
                    <!-- <b-button
                      size="sm"
                      @click="downloadCSV('normal')"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 mb-1"
                    >
                      Export Revenue CSV
                    </b-button> -->
                    <b-button
                      @click="downloadExport"
                      type="submit"
                      variant="outline-success"
                      class="mr-1"
                    >
                      Export
                    </b-button>
                  </b-col>
                  <b-col md="2" lg="2" sm="12"> </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <b-row>
      <b-col cols="12" md="4">
        <b-button
          class="mb-1"
          @click="onInputAdd"
          size="sm"
          variant="outline-primary"
        >
          Back
        </b-button>
      </b-col>
    </b-row>
    <b-badge variant="info">Total : {{ this.totalRows }}</b-badge>
    <b-card no-body>
      <b-table
        bordered
        v-if="$can('read', 'revenue')"
        ref="selectableTable"
        :fields="fields"
        :items="revenueDataList"
        responsive
        empty-text="No  records found"
        show-empty
        small
        hover
      >
        <template #cell(sr)="data">
          {{ data.index + 1 + (currentPage - 1) * limit }}
        </template>

        <template #cell(amount)="data">
          <div class="text-nowrap" style="text-align: right">
            <b>{{
              data.item.selectedCurrency +
              " " +
              (data.item.amount ? formatCurrency(data.item.amount) : "0") +
              " "
            }}</b>
          </div>
        </template>

        <template #cell(duedate)="data">
          <b>{{
            data.item.duedate
              ? moment(data.item.duedate).format("DD/MM/YYYY")
              : ""
          }}</b>
        </template>
        <template #cell(updatedAt)="data">
          <b>{{ moment(data.item.updatedAt).format("DD/MM/YYYY") }}</b>
        </template>
      </b-table>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                @change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BProgress,
  BBadge,
  BButton,
  BTooltip,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BTabs,
  BTab,
  VBTooltip,
  BPagination,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import {
  GetVendorAdditionalRevenueData,
  DeleteRevenueById,
} from "@/apiServices/RevenueServies";
import vSelect from "vue-select";
import moment from "moment";
import { getAllData } from "@/apiServices/MastersServices";
import { getAllVendor } from "@/apiServices/VendorServices";
import { getAllMovie } from "@/apiServices/MovieServices";
import Ripple from "vue-ripple-directive";
import { TokenService } from "@/apiServices/storageService";

import flatPickr from "vue-flatpickr-component";
import { downloadFromURL } from "@/utils/helpers";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { formatCurrency } from "@/utils/helpers";

export default {
  components: {
    BCard,
    BTable,
    BProgress,
    BBadge,
    vSelect,
    BButton,
    BTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    flatPickr,
    BTabs,
    BTab,
    BFormRadio,
    VBTooltip,
    BPagination,
    ToastificationContentVue,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      stickyHeader: true,
      selectAllStatus: "0",
      filterTempTerritory: null,
      optionTempTerritory: [],
      sub_rights_list: [],
      subRights: [],
      filterTempRights: null,
      optionTempRights: [],
      filterTempMovie: null,
      optionTempMovie: [],
      revenueDateRange: null,
      selectedCustomer: null,
      selectedRight: null,
      optionCustomer: [],
      relevanceOptions: [
        { title: "All", value: null },
        { title: "Relevant For Royalty Report", value: 1 },
        { title: "Not Relevant For Royalty Report", value: 0 },
      ],
      optionRights: [
        { title: "Theatrical Rights", value: 1 },
        { title: "Other Rights", value: 2 },
      ],

      selectedStatus: null,
      selectedRelevance: 1,
      setIsInvoice: false,
      optionStatus: [
        { title: "Billed", value: 1 },
        { title: "Unbilled", value: 0 },
        // { title: "Generate Manual Invoice", value: 3 },
      ],
      moment: moment,
      currentPage: 1,
      totalPages: 0,
      limit: 25,
      totalRows: null,
      perPageOptions: [25, 50, 100],
      fields: [
        { key: "sr", label: "SR" },

        { key: "invoice_unique_id", label: "Invoice No. " },
        { key: "company", label: "Customer" },

        { key: "additional_cost_name", label: "Cost Name" },
        { key: "quantity", label: "Quantity" },
        { key: "amount", label: "Amount" },
        { key: "duedate", label: "Due Date" },

        { key: "updatedAt", label: "Added on" },
      ],

      revenueDataList: [],
      revenueDataListAll: [],
      selectedRevenueItems: [],
      showGenerateInvoice: false,
      filtersHidden: true,
      selectedPage: "additional",
      optionsPage: [
        { text: "Add Revenue List", value: "revenue" },
        { text: "Additional Cost List", value: "additional" },
      ],
    };
  },

  watch: {
    selectedRevenueItems() {
      if (this.selectedRevenueItems.length > 0) {
        if (this.selectedRevenueItems.length != this.revenueDataList.length) {
          this.selectAllStatus = "0";
        } else {
          this.selectAllStatus = "1";
        }
      } else {
      }
    },
  },
  async beforeMount() {
    this.getAllAdditionalRevenueData();
    this.GetAllVendor();
    // this.GetAllMovie();
    // const response1 = await getAllData({
    //   master: "sub_right",
    //   deleted: "0",
    // });
    // if (response1.status == 200) {
    //   console.log(response1.data.data.rows, "hh");
    //   this.sub_rights_list = response1.data.data.rows.filter((z) => {
    //     return z.lbl_value1 != "" && z.lbl_value1 != null;
    //   });
    // }
  },
  methods: {
    onInputAdd() {
      this.$router.push("/addrevenueMain?tabIndex=1");
    },
    formatCurrency,

    downloadExport(type) {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }
        let payload = {
          vendor_id: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : "",
        };

        let baseURL = `${process.env.VUE_APP_BASEURL}/revenue/sheet/download-additional-revenue?authorization=${token}`;

        const urlStr = this.getURLString(baseURL, payload);

        console.log({ urlStr });

        downloadFromURL(urlStr, "Revenue", "csv");
      } catch (error) {
        console.error(`Error in downloadCSV `, error);
      }
    },
    getURLString(baseURL, payload) {
      let urlKeyValuePairs = [];

      for (let key in payload) {
        urlKeyValuePairs.push(`${key}=${payload[key]}`);
      }

      return baseURL + "&" + urlKeyValuePairs.join("&");
    },
    async getAllAdditionalRevenueData() {
      this.selectedRevenueItems = [];
      this.revenueDataList = [];
      this.revenueDataListAll = [];
      this.optionTempTerritory = [];
      this.optionTempRights = [];

      try {
        let payload = {
          vendor_id: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : "",
          rightType: this.selectedRight ? this.selectedRight.value : "",
          billedStatus: this.selectedStatus ? this.selectedStatus.value : "",

          start_date: this.revenueDateRange
            ? this.revenueDateRange.split("to")[0]
            : "",
          end_date: this.revenueDateRange
            ? this.revenueDateRange.split("to")[1]
            : "",
          search: this.search,
          limit: this.limit,
          page: this.currentPage,
          movie_id:
            this.filterTempMovie && this.filterTempMovie.id
              ? this.filterTempMovie.id
              : null,
          subRights: this.subRights,
          relevant_for_royalty: this.selectedRelevance,
        };
        const response = await GetVendorAdditionalRevenueData(payload);
        if (response.data.status) {
          this.revenueDataListAll = response.data.data.data;
          this.revenueDataList = response.data.data.data;
          if (
            response.data.data.pagination &&
            response.data.data.pagination.total
          ) {
            this.totalRows = response.data.data.pagination.total;
          }
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async GetAllVendor() {
      try {
        const response = await getAllVendor({});
        if (response.data) {
          this.optionCustomer = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    async GetAllMovie() {
      this.optionTempMovie = [];
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionTempMovie = response.data.data.data;
          console.log(this.optionTempMovie, "dad");
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    async deleteRevenueById(id) {
      const response = await DeleteRevenueById({
        revenue_data_id: id,
      });
      if (response.data.status) {
        this.getAllAdditionalRevenueData();
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Deleted Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
      return this.$toast({
        component: ToastificationContentVue,
        props: {
          title: response.data.message || "Delete Failed",
          icon: "EditIcon",
          variant: "danger",
        },
      });
    },
    showDeleteConfirm(data) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, you want to delete?", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) this.deleteRevenueById(data.revenue_data_id);
        })
        .catch((err) => {
          // An error occurred
        });
    },
    hideShowFilter() {
      this.filtersHidden = !this.filtersHidden;
    },
    onPageChange() {
      this.$nextTick(() => {
        this.getAllAdditionalRevenueData();
      });
    },
    async onSearch() {
      this.setIsInvoice = false;
      this.getAllAdditionalRevenueData();
    },
    async resetForm() {
      this.selectedCustomer = null;
      this.selectedRight = null;
      this.selectedStatus = null;
      this.revenueDateRange = null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
